<template>
  <v-dialog
    v-model="dialog"
    max-width="1430px"
    min-width="750px"
    overflow="hidden"
    no-click-animation
    :fullscreen="fullscreen"
    @click:outside="close"
  >
    <v-card>
      <v-card-title>
        <div class="d-flex justify-space-between" style="width: 100%">
          <div>{{ camera_id }}</div>
          <div>{{ user_id }}</div>
          <div class="d-flex">
            <div>{{ image_time }}</div>
            <v-icon class="mr-1" @click="previousImage"
              >mdi-chevron-left</v-icon
            >
            <v-icon class="mr-1" @click="nextImage">mdi-chevron-right</v-icon>
          </div>
        </div>
        <div class="d-flex justify-space-between" style="width: 100%">
          <div class="d-flex">
            <v-checkbox
              dense
              hide-details
              label="select"
              v-model="selected"
              @change="toggle_select"
            >
            </v-checkbox>

            <v-icon
              color="primary"
              class="ml-2 mb-4"
              :disabled="!json_data"
              @click="downloadFile('json')"
              >mdi-download</v-icon
            >
            <v-icon
              color="primary"
              class="ml-2 mb-4"
              @click="downloadFile('image')"
              >mdi-image-area-close</v-icon
            >
          </div>
          <v-radio-group row v-model="sourceRadio">
            <v-radio
              label="Inference"
              value="inference"
              :disabled="!hasInference"
            ></v-radio>
            <v-radio
              label="Analytics"
              value="analytics"
              :disabled="!hasAnalytics"
            ></v-radio>
            <v-radio label="JSON" value="json"></v-radio>
          </v-radio-group>
        </div>
      </v-card-title>
      <v-card-text>
        <v-container fluid class="main-container">
          <v-row class="no-gutters">
            <v-col class="mr-2" v-show="showSideContainers">
              <div class="left-column">
                <v-card elevation="2" style="height: 100%">
                  <v-tabs v-model="tab" class="table_tabs_vp" height="25">
                    <v-tab>Inference</v-tab>
                    <v-tab>Animals</v-tab>
                    <v-tabs-items v-model="tab">
                      <v-tab-item>
                        <v-container fluid class="container-vp">
                          <v-card-title class="justify-start inference-title">
                            Calving
                          </v-card-title>
                          <v-container
                            fluid
                            class="toggle-switches-events container-vp"
                          >
                            <v-switch
                              :disabled="!has_contraction"
                              v-model="show_contraction"
                            >
                              <template #label>
                                <span>Tail Up</span>
                                <span
                                  class="d-inline-block"
                                  style="
                                    display: inline-block;
                                    width: 10px;
                                    height: 10px;
                                    background-color: rgb(60, 102, 104);
                                    margin-left: 40px;
                                    border-radius: 50%;
                                  "
                                ></span>
                              </template>
                            </v-switch>
                          </v-container>
                        </v-container>
                        <v-container fluid class="container-vp label-container">
                          <v-card-title class="justify-start inference-title">
                            Estrus
                          </v-card-title>
                          <v-container
                            fluid
                            class="toggle-switches-events container-vp"
                          >
                            <v-switch
                              :disabled="!has_estrus"
                              v-model="show_estrus"
                            >
                              <template #label>
                                <span>Heat</span>
                                <span
                                  style="
                                    display: inline-block;
                                    width: 10px;
                                    height: 10px;
                                    background-color: #a0215f;
                                    margin-left: 54px;
                                    border-radius: 50%;
                                  "
                                >
                                </span>
                              </template>
                            </v-switch>
                          </v-container>
                        </v-container>
                        <v-container
                          fluid
                          class="toggle-switches-events container-vp"
                        >
                          <v-switch
                            :disabled="!has_estrus"
                            v-model="show_estrus"
                          >
                            <template #label>
                              <span>Chin Rest</span>
                              <span
                                class="d-inline-block"
                                style="
                                  display: inline-block;
                                  width: 10px;
                                  height: 10px;
                                  background-color: rgb(116, 165, 169);
                                  margin-left: 20px;
                                  border-radius: 50%;
                                "
                              ></span>
                            </template>
                          </v-switch>
                        </v-container>
                        <v-container
                          fluid
                          class="toggle-switches-events container-vp"
                        >
                          <v-switch
                            :disabled="!has_estrus"
                            v-model="show_estrus"
                          >
                            <template #label>
                              <span>Mounting</span>
                              <span
                                class="d-inline-block"
                                style="
                                  display: inline-block;
                                  width: 10px;
                                  height: 10px;
                                  background-color: rgb(141, 82, 50);
                                  margin-left: 20px;
                                  border-radius: 50%;
                                "
                              ></span>
                            </template>
                          </v-switch>
                        </v-container>
                        <v-container fluid class="container-vp label-container">
                          <v-card-title class="justify-start inference-title">
                            Traits
                          </v-card-title>
                          <v-container
                            fluid
                            class="toggle-switches-events container-vp"
                          >
                            <v-switch
                              :disabled="!has_activity"
                              v-model="show_activity"
                            >
                              <template #label>
                                <span>Activity</span>
                                <span
                                  class="d-inline-block"
                                  style="
                                    display: inline-block;
                                    width: 10px;
                                    height: 10px;
                                    background-color: rgb(251, 172, 45);
                                    margin-left: 35px;
                                    border-radius: 50%;
                                  "
                                ></span>
                              </template>
                            </v-switch>
                          </v-container>
                        </v-container>
                        <v-container
                          fluid
                          class="toggle-switches-events container-vp"
                        >
                          <v-switch
                            :disabled="!has_grazing"
                            v-model="show_grazing"
                          >
                            <template #label>
                              <span>Grazing</span>
                              <span
                                class="d-inline-block"
                                style="
                                  display: inline-block;
                                  width: 10px;
                                  height: 10px;
                                  background-color: rgb(6, 104, 39);
                                  margin-left: 33px;
                                  border-radius: 50%;
                                "
                              ></span>
                            </template>
                          </v-switch>
                        </v-container>
                        <v-container
                          fluid
                          class="toggle-switches-events container-vp"
                        >
                          <v-switch
                            :disabled="!has_gender"
                            v-model="show_gender"
                          >
                            <template #label>
                              <span>Gender</span>
                              <span
                                class="d-inline-block"
                                style="
                                  display: inline-block;
                                  width: 10px;
                                  height: 10px;
                                  background-color: purple;
                                  margin-left: 36px;
                                  border-radius: 50%;
                                "
                              ></span>
                            </template>
                          </v-switch>
                        </v-container>
                        <v-container
                          fluid
                          class="toggle-switches-events container-vp"
                        >
                          <v-switch :disabled="!has_age" v-model="show_age">
                            <template #label>
                              <span>Age</span>
                              <span
                                class="d-inline-block"
                                style="
                                  display: inline-block;
                                  width: 10px;
                                  height: 10px;
                                  background-color: rgb(60, 102, 104);
                                  margin-left: 60px;
                                  border-radius: 50%;
                                "
                              ></span>
                            </template>
                          </v-switch>
                        </v-container>
                        <v-container fluid class="container-vp label-container">
                          <v-card-title class="justify-start inference-title">
                            Hunters
                          </v-card-title>
                          <v-container
                            fluid
                            class="toggle-switches-events container-vp"
                          >
                            <v-switch
                              :disabled="!has_predator"
                              v-model="show_predator"
                            >
                              <template #label>
                                <span>Predators</span>
                                <span
                                  style="
                                    display: inline-block;
                                    width: 10px;
                                    height: 10px;
                                    background-color: rgb(136, 53, 40);
                                    margin-left: 19px;
                                    border-radius: 50%;
                                  "
                                >
                                </span>
                              </template>
                            </v-switch>
                          </v-container>
                        </v-container>
                        <v-container fluid class="container-vp label-container">
                          <v-card-title class="justify-start inference-title">
                            Thermal
                          </v-card-title>
                          <v-container
                            fluid
                            class="toggle-switches-events container-vp"
                          >
                            <v-switch disabled>
                              <template #label>
                                <span>Heat Maps</span>
                              </template>
                            </v-switch>
                          </v-container>
                        </v-container>
                      </v-tab-item>
                      <v-tab-item>
                        <div v-if="!animals.length">
                          <div style="margin-top: 85%; margin-left: 10%">
                            <v-img
                              width="150px"
                              height="150px"
                              src="../assets/BETSY_wave_smile.png"
                              contain
                            ></v-img>
                          </div>
                          <v-spacer></v-spacer>
                          <div
                            style="
                              font-size: 14px;
                              letter-spacing: 2px;
                              color: #0090a4;
                              text-align: center;
                            "
                          >
                            ANIMAL
                          </div>
                          <div
                            style="
                              font-size: 14px;
                              letter-spacing: 2px;
                              color: #0090a4;
                              text-align: center;
                            "
                          >
                            IDENTIFICATION
                          </div>
                          <div
                            style="
                              font-size: 14px;
                              letter-spacing: 2px;
                              color: #0090a4;
                              text-align: center;
                            "
                          >
                            PENDING
                          </div>
                        </div>
                        <span v-else>
                          <div
                            :class="{
                              'custom-style': !displayingOneAnimal,
                            }"
                            v-if="!displayingOneAnimal"
                          >
                            <v-card
                              v-for="(animal, index) in animals"
                              :key="index"
                              @click="displayAnimal(animal)"
                              style="height: 101px; margin: 1%"
                              hover
                            >
                              <v-container>
                                <v-overlay
                                  opacity="0"
                                  absolute
                                  style="
                                    justify-content: start;
                                    align-items: start;
                                    pointer-events: none;
                                    margin-top: 5px;
                                  "
                                >
                                  <v-chip
                                    style="
                                      color: white;
                                      font-weight: 500;
                                      margin-bottom: -3px;
                                    "
                                    small
                                    :color="
                                      animal.tag_label &&
                                      animal.tag_color &&
                                      animal.tag_color != 'UNDEFINED'
                                        ? 'oc_' +
                                          animal.tag_color.toLowerCase() +
                                          ' darken-1'
                                        : 'grey darken-1'
                                    "
                                  >
                                    {{
                                      animal.tag_label
                                        ? animal.tag_label
                                        : "UND"
                                    }}
                                  </v-chip>
                                </v-overlay>
                                <v-row
                                  dense
                                  style="
                                    margin: 0 !important;
                                    padding: 0 !important;
                                  "
                                >
                                  <v-col cols="5">
                                    <v-img
                                      ref="animalCardImage"
                                      height="76px"
                                      class="ma-1"
                                      aspect-ratio="1"
                                      @error="() => handleImageError(animal)"
                                      :src="animal.imageUrl"
                                    ></v-img>
                                  </v-col>
                                  <v-col cols="7">
                                    <div class="text-caption">
                                      {{ animal.color && animal.color }}
                                    </div>
                                    <div class="text-caption">
                                      {{ animal.dob && animal.dob }}
                                    </div>
                                    <div class="text-caption">
                                      {{
                                        animal.sex && formatString(animal.sex)
                                      }}
                                    </div>
                                    <div class="text-caption">
                                      {{
                                        animal.classification &&
                                        formatString(animal.classification)
                                      }}
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card>
                          </div>
                          <div v-if="displayingOneAnimal">
                            <v-card
                              v-if="displayingOneAnimal && displayCurrentAnimal"
                              elevation="2"
                              height="80%"
                              style="padding: 5px 5px 0px 5px"
                            >
                              <v-overlay
                                opacity="0"
                                absolute
                                style="
                                  justify-content: start;
                                  align-items: start;
                                "
                              >
                                <div class="image_selector_ac">
                                  <span
                                    class="image_selector_icons_ac"
                                    style="margin-left: 120%; margin-top: 3%"
                                  >
                                    <v-icon
                                      medium
                                      style="color: red"
                                      @click="showOriginal"
                                      >mdi-close</v-icon
                                    ></span
                                  >
                                  <span
                                    class="image_selector_icons_ac"
                                    style="margin-left: 6em; margin-top: 12em"
                                    ><v-icon
                                      medium
                                      :color="
                                        front_image_select == true
                                          ? 'primary'
                                          : null
                                      "
                                      @click="toggle_animal_image()"
                                      >mdi-chevron-left</v-icon
                                    ><v-icon
                                      medium
                                      :color="
                                        front_image_select == false
                                          ? 'primary'
                                          : null
                                      "
                                      @click="toggle_animal_image()"
                                      >mdi-chevron-right</v-icon
                                    >
                                  </span>
                                </div>
                              </v-overlay>
                              <v-img
                                ref="animalImage"
                                height="35%"
                                style="border-radius: 4px"
                                aspect-ratio="1"
                                @error="
                                  () => handleImageError(displayCurrentAnimal)
                                "
                                :src="
                                  front_image_select
                                    ? displayCurrentAnimal.imageUrl
                                    : displayCurrentAnimal.imageUrlBody
                                "
                              >
                                <v-overlay
                                  opacity="0"
                                  absolute
                                  style="
                                    justify-content: start;
                                    align-items: start;
                                  "
                                >
                                  <v-chip
                                    style="color: white; font-weight: 500"
                                    small
                                    :color="
                                      displayCurrentAnimal.tag_color &&
                                      displayCurrentAnimal.tag_color !=
                                        'undefined'
                                        ? 'oc_' +
                                          displayCurrentAnimal.tag_color.toLowerCase() +
                                          ' darken-1'
                                        : 'grey darken-1'
                                    "
                                  >
                                    {{
                                      displayCurrentAnimal.tag_label
                                        ? displayCurrentAnimal.tag_label
                                        : "UND"
                                    }}
                                  </v-chip>
                                </v-overlay>
                              </v-img>

                              <div
                                style="
                                  font-size: 15px;
                                  letter-spacing: 2px;
                                  text-transform: uppercase;
                                  margin-left: 10px;
                                  margin-top: 10px;
                                  color: #0090a4;
                                  display: flex;
                                  justify-content: space-between;
                                "
                              >
                                {{ displayCurrentAnimal.name }}
                              </div>
                              <div
                                style="
                                  margin-top: 10px;
                                  margin-left: 10px;
                                  margin-right: 10px;
                                "
                              >
                                <v-select
                                  hide-details
                                  outlined
                                  append-icon=""
                                  readonly
                                  label="Species"
                                  dense
                                  :items="[
                                    { value: 'BISON', text: 'Bison' },
                                    { value: 'CAMEL', text: 'Camel' },
                                    { value: 'CAT', text: 'Cat' },
                                    { value: 'CATTLE', text: 'Cattle' },
                                    { value: 'DOG', text: 'Dog' },
                                    { value: 'ELK', text: 'Elk' },
                                    { value: 'GOAT', text: 'Goat' },
                                    { value: 'HORSE', text: 'Horse' },
                                    { value: 'SHEEP', text: 'Sheep' },
                                    { value: 'SWINE', text: 'Swine' },
                                    { value: 'OTHER', text: 'Other' },
                                  ]"
                                  v-model="displayCurrentAnimal.species"
                                />
                              </div>
                              <div
                                style="
                                  margin-top: 10px;
                                  margin-left: 10px;
                                  margin-right: 10px;
                                "
                              >
                                <v-text-field
                                  hide-details
                                  outlined
                                  readonly
                                  label="Breed"
                                  dense
                                  v-model="displayCurrentAnimal.breed"
                                />
                              </div>
                              <div
                                style="
                                  margin-top: 10px;
                                  margin-left: 10px;
                                  margin-right: 10px;
                                "
                              >
                                <v-text-field
                                  hide-details
                                  outlined
                                  readonly
                                  label="Color"
                                  dense
                                  v-model="displayCurrentAnimal.color"
                                />
                              </div>
                              <div
                                style="
                                  margin-top: 10px;
                                  margin-left: 10px;
                                  margin-right: 10px;
                                "
                              >
                                <v-select
                                  hide-details
                                  outlined
                                  append-icon=""
                                  readonly
                                  label="Sex"
                                  dense
                                  :items="[
                                    { value: 'MALE', text: 'Male' },
                                    { value: 'FEMALE', text: 'Female' },
                                    { value: 'OTHER', text: 'Other' },
                                  ]"
                                  v-model="displayCurrentAnimal.sex"
                                />
                              </div>
                              <div
                                style="
                                  margin-top: 10px;
                                  margin-left: 10px;
                                  margin-right: 10px;
                                "
                              >
                                <v-select
                                  hide-details
                                  outlined
                                  append-icon=""
                                  readonly
                                  label="Classification"
                                  dense
                                  hide-selected
                                  :items="[
                                    { value: 'BULL', text: 'Bull' },
                                    { value: 'CALF', text: 'Calf' },
                                    { value: 'COW', text: 'Cow' },
                                    { value: 'FREEMARTIN', text: 'Freemartin' },
                                    { value: 'HEIFER', text: 'Heifer' },
                                    { value: 'STEER', text: 'Steer' },
                                    { value: 'OTHER', text: 'Other' },
                                  ]"
                                  v-model="displayCurrentAnimal.classification"
                                />
                              </div>
                            </v-card>
                          </div>
                        </span>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-tabs>
                </v-card>
              </div>

              <!-- <div class="left-column" v-if="!animals.length">
                <v-card elevation="2" style="height: 100%">
                  <div style="margin-top: 85%; margin-left: 10%">
                    <v-img
                      width="150px"
                      height="150px"
                      src="../assets/BETSY_wave_smile.png"
                      contain
                    ></v-img>
                  </div>
                  <v-spacer></v-spacer>
                  <div
                    style="
                      font-size: 14px;
                      letter-spacing: 2px;
                      color: #0090a4;
                      text-align: center;
                    "
                  >
                    ANIMAL
                  </div>
                  <div
                    style="
                      font-size: 14px;
                      letter-spacing: 2px;
                      color: #0090a4;
                      text-align: center;
                    "
                  >
                    IDENTIFICATION
                  </div>
                  <div
                    style="
                      font-size: 14px;
                      letter-spacing: 2px;
                      color: #0090a4;
                      text-align: center;
                    "
                  >
                    PENDING
                  </div>
                </v-card>
              </div> -->
            </v-col>
            <v-col>
              <div class="middle">
                <div>
                  <div class="container-vp">
                    <div class="video-container paused vjs-control-bar">
                      <div class="panZoom-container">
                        <canvas
                          id="imageCanvas"
                          ref="imageCanvas"
                          width="952"
                          height="535.5"
                          @click="handleCanvasClick"
                        >
                        </canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img
                ref="hiddenImage"
                :src="`${cookie_bucket + image_path + '_event.jpg'}`"
                @load="updateCanvas"
                style="display: none"
              />
              <img
                src="../assets/Onecup-Player.png"
                style="display: none"
                id="logo"
              />
              <img
                src="../assets/icon_cowcalving_colour.svg"
                style="display: none"
                id="calving_cow_image"
              />
              <img
                src="../assets/icon_cowchinresting_colour.svg"
                style="display: none"
                id="chinresting_cow_image"
              />
              <img
                src="../assets/icon_cowmounting_colour.svg"
                style="display: none"
                id="mounting_cow_image"
              />
              <img
                src="../assets/icon_cowsitting_colour.svg"
                style="display: none"
                id="laying_cow_image"
              />
              <img
                src="../assets/icon_cowstanding_colour.svg"
                style="display: none"
                id="standing_cow_image"
              />
              <img
                src="../assets/icon_cowheat_color.svg"
                style="display: none"
                id="heat_cow_image"
              />
            </v-col>
            <v-col class="ml-2" v-show="showSideContainers">
              <div class="right-column">
                <v-card elevation="2" height="24em">
                  <v-tabs class="table_tabs_vp" grow height="25">
                    <v-tab>Overlay Controls</v-tab>
                  </v-tabs>

                  <v-container fluid class="container-vp">
                    <v-card-title class="justify-start inference-title">
                      Annotations
                    </v-card-title>
                    <v-container fluid class="toggle-switches container-vp">
                      <v-switch v-model="showbos">
                        <template #label>
                          <span>Bounding Box</span>
                        </template>
                      </v-switch>
                    </v-container>
                    <v-container fluid class="toggle-switches container-vp">
                      <v-switch v-model="bodyparts">
                        <template #label>
                          <span>Body Parts</span>
                        </template>
                      </v-switch>
                    </v-container>
                    <v-container fluid class="toggle-switches container-vp">
                      <v-switch v-model="keypoints">
                        <template #label>
                          <span>Key Points</span>
                        </template>
                      </v-switch>
                    </v-container>
                  </v-container>

                  <v-container fluid class="container-vp label-container">
                    <v-card-title class="justify-start inference-title">
                      Visuals
                    </v-card-title>
                    <v-container fluid class="toggle-switches container-vp">
                      <v-switch v-model="bbox_fill">
                        <template #label>
                          <span>Color Fill</span>
                        </template>
                      </v-switch>
                    </v-container>
                    <v-container fluid class="toggle-switches container-vp">
                      <v-switch v-model="activityIcons">
                        <template #label>
                          <span>Icons</span>
                        </template>
                      </v-switch>
                    </v-container>
                  </v-container>

                  <v-container fluid class="container-vp label-container">
                    <v-card-title class="justify-start inference-title">
                      Labels
                    </v-card-title>
                    <v-container fluid class="toggle-switches container-vp">
                      <v-switch v-model="labels">
                        <template #label>
                          <span>Anatomy Labels</span>
                        </template>
                      </v-switch>
                    </v-container>
                    <v-container fluid class="toggle-switches container-vp">
                      <v-switch v-model="inference_labels">
                        <template #label>
                          <span>Classifications </span>
                        </template>
                      </v-switch>
                    </v-container>
                  </v-container>
                </v-card>
                <div style="width: 100%; margin-top: 2%">
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <input
                      type="text"
                      v-model="search"
                      placeholder="Search Tag"
                      style="
                        border: 1px solid silver;
                        padding: 2px;
                        border-radius: 5px;
                        width: 40%;
                      "
                    />
                    <v-btn
                      x-small
                      text
                      @click="sortBy('tag_label')"
                      elevation="2"
                      >A-Z</v-btn
                    >
                    <v-btn
                      x-small
                      text
                      @click="sortBy('tag_color')"
                      elevation="2"
                      >Color</v-btn
                    >
                  </div>

                  <div style="height: 12em">
                    <v-card
                      style="height: 100%"
                      class="overflow-auto"
                      elevation="2"
                    >
                      <v-card-text>
                        <div v-if="!(tags_list.length > 0)">
                          <div style="margin-top: 10px">
                            <div
                              style="
                                font-size: 14px;
                                letter-spacing: 2px;
                                color: #0090a4;
                                text-align: center;
                              "
                            >
                              NO
                            </div>
                            <div
                              style="
                                font-size: 14px;
                                letter-spacing: 2px;
                                color: #0090a4;
                                text-align: center;
                              "
                            >
                              TAGS
                            </div>
                            <div
                              style="
                                font-size: 14px;
                                letter-spacing: 2px;
                                color: #0090a4;
                                text-align: center;
                              "
                            >
                              READ
                            </div>
                          </div>
                        </div>
                        <div
                          v-else
                          style="display: flex; flex-wrap: wrap; gap: 5px"
                        >
                          <v-chip
                            v-for="tags in filteredSearchTags"
                            :key="tags.tracking_id"
                            class="chip-style"
                            small
                            :class="[
                              isSelected(tags.tracking_id)
                                ? ''
                                : tags.tag_color
                                ? `oc_${tags.tag_color.toLowerCase()} darken-1`
                                : 'grey darken-1',
                            ]"
                            :style="{
                              backgroundColor: isSelected(tags.tracking_id)
                                ? 'white'
                                : '',
                              color: isSelected(tags.tracking_id)
                                ? '#0090a4'
                                : 'white',
                              border: isSelected(tags.tracking_id)
                                ? '1px solid #0090a4'
                                : '1px solid white',
                            }"
                            @click="changeSelectedTrackingID(tags.tracking_id)"
                          >
                            {{ tags.tag_label }}
                          </v-chip>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                </div>
              </div></v-col
            >
          </v-row>
          <div
            style="
              display: flex;
              justify-content: space-between;
              margin-top: 1%;
            "
          >
            <span style="font-size: 14px; letter-spacing: 2px; color: #0090a4"
              >Version No: 2.2.1</span
            >
            <span style="font-size: 14px; letter-spacing: 2px; color: #0090a4"
              >Available Images: {{ this.availableImagesLength }}</span
            >
            <span style="font-size: 14px; letter-spacing: 2px; color: #0090a4"
              >Total Animals: {{ this.animalsInFrame }}</span
            >
            <span style="font-size: 14px; letter-spacing: 2px; color: #0090a4"
              >Current Image Index: {{ this.image_index + 1 }}</span
            >
            <span style="font-size: 14px; letter-spacing: 2px; color: #0090a4"
              >(W-{{ this.imageWidth }}) (H-{{ this.imageHeight }})</span
            >
            <span style="font-size: 14px; letter-spacing: 2px; color: #0090a4"
              >Last updated: Sep 04 '24</span
            >
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { eventBus } from "../main";
import JSZip from "jszip";
import Panzoom from "@panzoom/panzoom";
import placeholder_img from "@/assets/cover_brown.png";
import placeholder_body_img from "@/assets/cover_brown_body.png";
import moment from "moment";
import axios from "axios";
//import VueLoadImage from "vue-load-image";

// @vuese
// @group Components
// The interactive card for images
export default {
  props: {
    // Turn on/off the 'filtered content' option
    noFilter: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    // "vue-load-image": VueLoadImage,
  },
  data() {
    return {
      dialog: false,
      search: "",
      tabs: null,
      keyDownListener: null,
      showSideContainers: window.innerWidth >= 1450,
      keyUpListener: null,
      json_loading: false,
      options: {
        json: false,
        analytics: false,
        inference: false,
      },
      sourceRadio: "inference",
      analytics_calving: null,
      analytics_predator: null,
      analytics_estrus: null,
      image_path: "",
      json_annotation_div: null,
      imageWidth: 0,
      imageHeight: 0,
      json_data: null,
      camera_id: "",
      user_id: "",
      version: "",
      tab: null,
      image_time: "",

      sourceComponent: "",
      db_classifications: [],
      items: [
        { title: "Predators", value: false, color: "#FF0000" },
        { title: "Heat", value: false, color: "#800080" },
        // ... add other items here
      ],
      // annotations

      tags_list: [],
      animals: [],
      selectedTrackingID: null,
      placeholder_img: placeholder_img,
      placeholder_body_img: placeholder_body_img,
      displayCurrentAnimal: [],
      front_image_select: true,
      displayingOneAnimal: false,

      availableImagesLength: null,
      image_index: null,

      list_of_keypoints: [],
      list_of_lines: [],
      list_of_all_bbox_classifications: [],
      list_of_all_bbox_classifications_json: [],

      has_contraction: false,
      has_contraction_json: false,

      has_species: false,
      has_species_json: false,

      has_gender: false,
      has_gender_json: false,

      has_grazing: false,
      has_grazing_json: false,

      has_activity: false,
      has_activity_json: false,

      has_age: false,
      has_age_json: false,

      has_predator: false,
      has_predator_json: false,

      has_estrus: false,
      has_estrus_json: false,

      topology38: [
        [7, 6],
        [12, 11],
        [15, 6],
        [15, 11],
        [6, 5],
        [11, 10],
        [10, 5],
        [10, 0],
        [5, 0],
        [3, 4],
        [1, 2],
        [27, 29],
        [29, 28],
        [24, 25],
        [25, 26],
        [15, 16],
        [16, 19],
        [19, 24],
        [19, 27],
        [19, 21],
        [21, 37],
        [21, 38],
        [37, 36],
        [38, 36],
        [21, 20],
        [20, 22],
        [22, 23],
        [20, 32],
        [20, 33],
        [33, 35],
        [35, 34],
        [32, 30],
        [30, 31],
        [0, 1],
        [3, 1],
        [4, 1],
        [5, 3],
        [10, 4],
      ],
      topology52: [
        [10, 5],
        [10, 11],
        [5, 6],
        [6, 7],
        [11, 12],
        [0, 5],
        [0, 10],
        [1, 2],
        [3, 4],
        [6, 15],
        [11, 15],
        [15, 16],
        [16, 19],
        [19, 20],
        [20, 21],
        [21, 22],
        [22, 23],
        [23, 24],
        [24, 25],
        [25, 26],
        [26, 27],
        [27, 28],
        [2, 17],
        [17, 18],
        [31, 32],
        [32, 30],
        [30, 29],
        [29, 33],
        [35, 37],
        [37, 36],
        [36, 34],
        [34, 38],
        [40, 42],
        [42, 39],
        [39, 41],
        [41, 43],
        [45, 47],
        [47, 46],
        [46, 44],
        [44, 48],
        [6, 8],
        [8, 9],
        [11, 13],
        [13, 14],
        [49, 50],
        [49, 51],
      ],
      showbos: true,
      bodyparts: true,
      keypoints: false,
      labels: true,
      inference_labels: true,
      bbox_fill: false,
      isbbox_fill: false,
      show_contraction: false,
      show_contraction_classification: false,
      show_predator: false,
      show_calving: false,
      show_ocr: false,
      show_activity: false,
      show_grazing: false,
      show_gender: false,
      show_age: false,
      show_suckling: false,
      show_delivery: false,
      show_mounting: false,
      show_resting_chin: false,
      show_in_heat: false,
      show_estrus: false,
      activityIcons: false,

      isShowBos: false,
      isBodyParts: false,
      isKeyPoints: false,
      isLabels: false,
      isInferenceLabels: false,
      isShowContraction: false,
      isShowContractionClassification: false,
      isShowPredator: false,
      isShowActivity: false,
      isShowAge: false,
      isShowEstrus: false,
      isActivityIcons: false,
      isShowGrazing: false,
      isShowGender: false,

      fullscreen: false,
      bbox_list: [],
      analytics_annotations_bbox: [],
      analytics_annotations_bp: [],
      analytics_annotations_classifications: {},
      body_parts_list: [],
      classificationInfo: [],
      isAnalytics: false,
      hasInference: false,
      hasAnalytics: false,
      hasJsonData: false,
      isEstrus: false,
      show_filtered: false,

      json_cache: {},

      selected: false,
      json_selected: false,
      image_width: 2560,
      image_data: {},
      animalsInFrame: null,
    };
  },
  watch: {
    sourceRadio() {
      if (this.sourceRadio === "analytics") {
        this.processAnnotationsAnalytics();
        this.updateCanvas();
      } else {
        this.processAnnotations();
      }
    },
    showbos() {
      this.updateCanvas();
    },
    isEstrus() {
      if (this.isEstrus) {
        this.has_estrus = true;
      } else {
        this.has_estrus = false;
      }
    },
    show_estrus() {
      this.updateCanvas();
    },
    json_selected() {
      this.processAnnotations();
    },
    selectedTrackingID() {
      this.updateCanvas();
    },
    bodyparts() {
      this.updateCanvas();
    },
    keypoints() {
      this.updateCanvas();
    },
    labels() {
      if (this.labels) {
        this.showbos = true;
      }
      this.updateCanvas();
    },
    activityIcons() {
      this.updateCanvas();
    },
    inference_labels() {
      this.updateCanvas();
    },
    bbox_fill() {
      this.updateCanvas();
    },
    show_contraction() {
      if (!this.inference_labels) {
        this.inference_labels = true;
      }
      this.updateCanvas();
    },
    show_predator() {
      if (!this.inference_labels) {
        this.inference_labels = true;
      }
      this.updateCanvas();
    },
    show_calving() {
      if (!this.inference_labels) {
        this.inference_labels = true;
      }
      this.updateCanvas();
    },
    show_activity() {
      if (!this.inference_labels) {
        this.inference_labels = true;
      }
      this.updateCanvas();
    },
    show_grazing() {
      if (!this.inference_labels) {
        this.inference_labels = true;
      }
      this.updateCanvas();
    },
    show_gender() {
      if (!this.inference_labels) {
        this.inference_labels = true;
      }
      this.updateCanvas();
    },
    show_age() {
      if (!this.inference_labels) {
        this.inference_labels = true;
      }
      this.updateCanvas();
    },
    show_delivery() {
      if (!this.inference_labels) {
        this.inference_labels = true;
      }
      this.updateCanvas();
    },
    show_mounting() {
      if (!this.inference_labels) {
        this.inference_labels = true;
      }
      this.updateCanvas();
    },
    show_resting_chin() {
      if (!this.inference_labels) {
        this.inference_labels = true;
      }
      this.updateCanvas();
    },
    show_in_heat() {
      if (!this.inference_labels) {
        this.inference_labels = true;
      }
      this.updateCanvas();
    },
    show_vehicle_type() {
      if (!this.inference_labels) {
        this.inference_labels = true;
      }
      this.updateCanvas();
    },
    show_vehicle_make() {
      if (!this.inference_labels) {
        this.inference_labels = true;
      }
      this.updateCanvas();
    },
    show_human_pose() {
      if (!this.inference_labels) {
        this.inference_labels = true;
      }
      this.updateCanvas();
    },
    show_filtered() {
      if (this.show_filtered) {
        this.bodyparts = false;
        this.keypoints = false;
      }
      this.updateCanvas();
    },
    dialog() {
      this.$store.commit("SET_IMAGE_CARD_DIALOG", this.dialog);
    },
  },
  methods: {
    /**
     *
     * @param {Object} params
     * @param {String} params.image_path
     * @param {String} params.camera_id
     * @param {String} params.image_time
     * @param {Number} params.image_index
     *
     */
    async open(params) {
      this.dialog = true;

      this.sourceComponent = params.sourceComponent || null;
      this.image_path = params.image_path;
      this.camera_id = params.camera_id || "";
      this.image_time = params.image_time || "";
      this.bbox_list = params.bbox_list || [];
      console.log(params.img)
      this.imageWidth = params.img.width;
      this.imageHeight = params.img.height;
      this.body_parts_list = params.body_parts_list || [];

      this.image_index = params.image_index;
      this.user_id = params.isAnalytics
        ? params.customer_information || ""
        : params.user_id || "";

      this.selected = params.selected;
      this.availableImagesLength = params.imagesLength;
      this.image_index = params.image_index;
      this.classificationInfo = params.classificationInfo || [];
      this.isAnalytics = params.isAnalytics || false;
  

      if (this.sourceComponent != null) {
        this.sourceRadio = "analytics";
        this.isEstrus = params?.estrusFilterApplied
          ? params.estrusFilterApplied
          : false;
      } else {
        this.isEstrus = params.img?.estrusFilterApplied
          ? params.img.estrusFilterApplied
          : false;

        if (!this.isEstrus) {
          this.analytics_calving = params.img.analytics_calving;
          this.analytics_predator = params.img.analytics_predator;
          this.analytics_estrus = params.img.analytics_estrus;
        }
      }

      this.tags_list = [];
      this.animals = [];
      this.image_data = {};
      this.showOriginal();
      this.displayCurrentAnimal = {};
      this.resetJsonFilters();
      this.resetDbFilters();
      var passed = await this.delayGate(params.image_path);
      if (passed) {
        this.image_data = params.img;
        if (this.image_data?.tags) {
          let tags_list = this.image_data.tags;
          if (tags_list.length > 0) {
            let customer_id = this.image_data.customer_id;
            let query = this.buildQuery(customer_id, tags_list);

            try {
              const response = await axios.post(
                this.image_data.hasura_url,
                {
                  query: query,
                },
                {
                  headers: this.image_data.hasura_header,
                }
              );
              const tags = response.data.data.list_animals;
              tags.forEach((tag) => {
                const matchingTags = this.image_data.tags.filter((imgTag) => {
                  const labelMatch =
                    imgTag.tag_label.toLowerCase() ===
                    tag.tag_label.toLowerCase();
                  let tagColorMatch = false;
                  if (
                    (imgTag.tag_color === undefined &&
                      tag.tag_color === undefined) ||
                    (imgTag.tag_color &&
                      tag.tag_color &&
                      imgTag.tag_color.toLowerCase() ===
                        tag.tag_color.toLowerCase())
                  ) {
                    tagColorMatch = true;
                  }

                  return labelMatch && tagColorMatch;
                });

                if (matchingTags.length > 0) {
                  matchingTags.forEach((matchingTag) => {
                    this.tags_list.push({
                      tag_label: matchingTag.tag_label,
                      tag_color: matchingTag.tag_color,
                      tracking_id: matchingTag.tracking_id,
                    });

                    // use this
                    let imageUrl = tag.image_id
                      ? this.cropped_url(
                          tag.image_id,
                          tag.image_timestamp,
                          tag.customer_id,
                          "head"
                        )
                      : "";
                    tag.imageUrl = imageUrl;
                    tag.imageUrlBody = tag.image_id
                      ? this.cropped_url(
                          tag.image_id,
                          tag.image_timestamp,
                          tag.customer_id,
                          "body"
                        )
                      : "";
                    this.animals.push(tag);
                  });
                }
              });
            } catch (error) {
              console.log(error);
            }
          }
        }
        this.processAnnotations();
        this.processAnnotationsAnalytics();
        await this.fetchJSON();
        const panZoomContainer = document.querySelector(".panZoom-container");
        this.panzoom = Panzoom(panZoomContainer, {
          contain: "outside",
        });

        panZoomContainer.addEventListener("wheel", this.panzoom.zoomWithWheel);
        await this.$nextTick();
        this.updateCanvas();
      }
      return params;
    },

    checkScreenWidth() {
      this.showSideContainers = window.innerWidth >= 1465;
      // console.log("check width");
      // if (this.showSideContainers) {
      //   setTimeout(() => {
      //     this.left_container = true;
      //     this.right_container = true;
      //   }, 600);
      // } else {
      //   this.left_container = false;
      //   this.right_container = false;
      // }
    },

    processAnalytics(item, type) {
      if (type == "predator") {
        let analytics_bbox;
        if (
          item.bbox &&
          item.bbox?.x &&
          item.bbox?.y &&
          item.bbox?.w &&
          item.bbox?.h
        ) {
          analytics_bbox = {
            x1: item.bbox.x,
            y1: item.bbox.y,
            w: item.bbox.w,
            h: item.bbox.h,
            tracking_id: item.tracking_id,
            species: item.species_label,
            speciesConf: item.species_conf,
          };
          this.analytics_annotations_bbox.push(analytics_bbox);

          if (item.bbox_x && item.bbox_y && item.bbox_w && item.bbox_h) {
            this.analytics_annotations_bbox.push({
              x1: item.bbox_x,
              y1: item.bbox_y,
              w: item.bbox_w,
              h: item.bbox_h,
              tracking_id: item.tracking_id,
            });
          }

          let predator_bbox = analytics_bbox;
          let classification = undefined;
          if (
            item &&
            item.breed_label !== undefined &&
            item.breed_conf !== undefined
          ) {
            classification = [
              {
                label1: item.breed_label,
                conf1: item.breed_conf,
              },
            ];
            this.analytics_annotations_classifications["predator"] = {
              bbox: predator_bbox,
              classification: classification,
            };
          }
        }
      }
      if (type == "estrus") {
        let analytics_bbox;
        if (item.bbox_x && item.bbox_y && item.bbox_w && item.bbox_h) {
          this.analytics_annotations_bbox.push({
            x1: item.bbox_x,
            y1: item.bbox_y,
            w: item.bbox_w,
            h: item.bbox_h,
            tracking_id: item.tracking_id,
            species: item.species_label,
            speciesConf: item.species_conf,
          });
          analytics_bbox = this.analytics_annotations_bbox[0];
        }

        if (
          item.oo_bbox_x &&
          item.oo_bbox_y &&
          item.oo_bbox_w &&
          item.oo_bbox_h
        ) {
          this.analytics_annotations_bbox.push({
            x1: item.oo_bbox_x,
            y1: item.oo_bbox_y,
            w: item.oo_bbox_w,
            h: item.oo_bbox_h,
            tracking_id: item.oo_tracking_id,
          });
        }

        if (this.analytics_annotations_bbox.length >= 2) {
          const minX = Math.min(
            ...this.analytics_annotations_bbox.map((bbox) => bbox.x1)
          );
          const minY = Math.min(
            ...this.analytics_annotations_bbox.map((bbox) => bbox.y1)
          );
          const maxX = Math.max(
            ...this.analytics_annotations_bbox.map((bbox) => bbox.x1 + bbox.w)
          );
          const maxY = Math.max(
            ...this.analytics_annotations_bbox.map((bbox) => bbox.y1 + bbox.h)
          );

          const encompassingWidth = maxX - minX;
          const encompassingHeight = maxY - minY;
          let bbox = {
            x1: minX,
            y1: minY,
            w: encompassingWidth,
            h: encompassingHeight,
          };
          this.analytics_annotations_bbox.push(bbox);
          analytics_bbox = bbox;
        }
        let estrus_bbox = analytics_bbox;
        let classification = undefined;

        if (
          item &&
          item.class_label !== undefined &&
          item.class_conf !== undefined &&
          item.class_label_2 !== undefined &&
          item.class_conf_2 !== undefined
        ) {
          classification = [
            {
              label1: item.class_label,
              conf1: item.class_conf,
              label2: item.class_label_2,
              conf2: item.class_conf_2,
            },
          ];
        }

        this.analytics_annotations_classifications["estrus"] = {
          bbox: estrus_bbox,
          classification: classification,
        };
      }

      // Calving

      if (type === "calving") {
        let analytics_bbox =
          item.bbox &&
          item.species_label !== undefined &&
          item.species_conf !== undefined
            ? [
                {
                  x1: item.bbox?.x,
                  y1: item.bbox?.y,
                  w: item.bbox?.w,
                  h: item.bbox?.h,
                  species: item.species_label,
                  speciesConf: item.species_conf,
                },
              ]
            : undefined;

        this.analytics_annotations_bbox = analytics_bbox;

        //bodyparts
        this.analytics_annotations_bp = [
          ...(item.tail_bbox && item.tail_conf !== undefined
            ? [{ bbox: item.tail_bbox, conf: item.tail_conf, label: "tail" }]
            : []),
          ...(item.tag_1_bbox && item.tag_1_conf !== undefined
            ? [{ bbox: item.tag_1_bbox, conf: item.tag_1_conf, label: "tag" }]
            : []),
          ...(item.tag_2_bbox && item.tag_2_conf !== undefined
            ? [{ bbox: item.tag_2_bbox, conf: item.tag_2_conf, label: "tag" }]
            : []),
        ];
        let calving_bbox = analytics_bbox[0];
        let classification = undefined;

        // Checking that item and all required properties exist
        if (
          item &&
          item.contraction_label !== undefined &&
          item.contraction_conf !== undefined &&
          item.contraction_label_2 !== undefined &&
          item.contraction_conf_2 !== undefined
        ) {
          classification = [
            {
              label1: item.contraction_label,
              conf1: item.contraction_conf,
              label2: item.contraction_label_2,
              conf2: item.contraction_conf_2,
            },
          ];
        }

        this.analytics_annotations_classifications["calving"] = {
          bbox: calving_bbox,
          classification: classification,
        };
      }
    },
    processAnnotationsAnalytics() {
      if (this.sourceComponent != null || this.isEstrus) {
        this.hasAnalytics = true;
        this.hasInference = false;
        this.sourceRadio = "analytics";
      } else {
        this.hasAnalytics =
          this.analytics_calving ||
          this.analytics_predator ||
          this.analytics_estrus;
        this.analytics_annotations_bbox = [];
        this.analytics_annotations_bp = [];
        this.analytics_annotations_classifications = {};
        if (this.hasAnalytics) {
          if (this.analytics_calving) {
            this.processAnalytics(this.analytics_calving, "calving");
          }
          if (this.analytics_estrus) {
            this.processAnalytics(this.analytics_estrus, "estrus");
          }
          if (this.analytics_predator) {
            this.processAnalytics(this.analytics_predator, "predator");
          }
        }
      }
    },
    confToPercent(conf) {
      return (conf * 100).toFixed(0) + "%";
    },
    percentToConf(percentString) {
      const value = parseFloat(percentString.replace("%", ""));
      return value / 100;
    },
    processJson() {
      this.list_of_all_bbox_classifications_json = [];
      let j_data = this.json_data?.frame[0]?.objects;
      if (j_data) {
        this.hasJsonData = true;
        j_data.forEach((item) => {
          if (Object.prototype.hasOwnProperty.call(item, "classifications")) {
            let bboxObj = {
              x1: item.bbox.x1,
              y1: item.bbox.y1,
              w: item.bbox.w,
              h: item.bbox.h,
              species: item.label,
              speciesConf: this.percentToConf(item.conf),
              tracking_id: item.tracking_id,
            };
            item.classifications.forEach((classification) => {
              const type = classification.type;
              const label = classification.label;
              const label_conf = this.percentToConf(classification.conf);
              switch (type) {
                case "SGIE_CONTRACTION_CLASSIFICATION":
                  if (label === "tail_up") {
                    this.has_contraction_json = true;
                    bboxObj.contraction = label;
                    bboxObj.contractionConf = label_conf;
                  }
                  break;

                case "SGIE_ACTIVITY_CLASSIFICATION":
                  this.has_activity_json = true;
                  bboxObj.activity = label;
                  bboxObj.activityConf = label_conf;
                  break;

                case "SGIE_MOUNTING_CLASSIFICATION":
                  if (label != "not_mounting") {
                    this.has_estrus_json = true;
                    bboxObj.mounting = label;
                    bboxObj.mountingConf = label_conf;
                  }
                  break;
                case "SGIE_COW_HEAD_UP":
                  if (label == "head_down" || label == "head_up") {
                    this.has_grazing_json = true;
                    bboxObj.grazing = label;
                    bboxObj.grazingConf = label_conf;
                  }
                  break;

                case "SGIE_COW_SEX":
                  if (label == "cow" || label == "bull") {
                    this.has_gender_json = true;
                    bboxObj.gender = label;
                    bboxObj.genderConf = label_conf;
                  }
                  break;

                case "SGIE_CHINREST_CLASSIFICATION":
                  if (label === "resting_chin") {
                    this.has_estrus_json = true;
                    bboxObj.resting_chin = label;
                    bboxObj.resting_chinConf = label_conf;
                  }
                  break;

                // case "SGIE_HEAT_DETECTION_CLASSIFICATION":
                //   if (label === "in_heat") {
                //     this.has_in_heat = true;
                //     bboxObj.in_heat = label;
                //     bboxObj.in_heat = label_conf;
                //   }
                //   break;

                case "SGIE_SPECIES_CLASSIFICATION":
                  bboxObj.label = label;
                  bboxObj.conf = label_conf;
                  break;

                case "SGIE_AGE_CLASSIFICATION":
                  bboxObj.age = label;
                  bboxObj.ageConf = label_conf;
                  this.has_age_json = true;
                  break;
              }
            });

            let compositeKey = `${bboxObj.tracking_id}-${bboxObj.species}-${bboxObj.x1}-${bboxObj.y1}-${bboxObj.w}-${bboxObj.h}`;
            if (
              !this.list_of_all_bbox_classifications_json.some(
                (e) =>
                  `${e.tracking_id}-${e.label}-${e.x1}-${e.y1}-${e.w}-${e.h}` ===
                  compositeKey
              )
            ) {
              this.list_of_all_bbox_classifications_json.push(bboxObj);
            }
          }
        });
      }
    },
    resetDbFilters() {
      this.list_of_all_bbox_classifications = [];
      this.has_contraction = false;
      this.has_activity = false;
      this.has_species = false;
      this.has_age = false;
      this.has_estrus = false;
      this.has_predator = false;
      this.has_grazing = false;
      this.has_gender = false;
    },
    resetJsonFilters() {
      this.has_contraction_json = false;
      this.has_activity_json = false;
      this.has_species_json = false;
      this.has_age_json = false;
      this.has_estrus_json = false;
      this.has_predator_json = false;
      this.has_grazing_json = false;
      this.has_gender_json = false;
    },
    processAnnotations() {
      this.resetDbFilters();
      if (this.sourceComponent == null && !this.isEstrus) {
        let j_data = this.image_data;
        if (Object.keys(j_data).length > 0) {
          const { body_parts_list, pgies } = j_data;
          if (body_parts_list.length > 0) {
            this.body_parts_list = body_parts_list;
          }

          if (pgies.length > 0) {
            pgies.forEach((pgie) => {
              if (Object.prototype.hasOwnProperty.call(pgie, "bbox")) {
                let bboxObj = {
                  x1: pgie.bbox.x,
                  y1: pgie.bbox.y,
                  w: pgie.bbox.w,
                  h: pgie.bbox.h,
                  species: pgie.class_label,
                  speciesConf: pgie.class_conf,
                  tracking_id: pgie.tracking_id,
                };

                if (
                  Object.prototype.hasOwnProperty.call(pgie, "classifications")
                ) {
                  const {
                    activity_conf,
                    activity_label,
                    age_conf,
                    age_label,
                    breed_label,
                    breed_conf,
                    contraction_label,
                    contraction_conf,
                    species_label,
                    species_conf,
                  } = pgie.classifications;

                  // Age label
                  if (age_label) {
                    this.has_age = true;
                    if (age_conf) {
                      bboxObj.ageConf = this.confToPercent(age_conf);
                    }
                    bboxObj.age = age_label;
                  }

                  if (species_label) {
                    // this.has_age = true;
                    if (species_conf) {
                      bboxObj.conf = species_conf;
                    }
                    bboxObj.label = species_label;
                  }

                  // Activity Label
                  if (activity_label) {
                    this.has_activity = true;
                    if (activity_conf) {
                      bboxObj.activityConf = this.confToPercent(activity_conf);
                    }
                    bboxObj.activity = activity_label;
                  }

                  //Tail UP
                  if (contraction_label && contraction_label === "tail_up") {
                    this.has_contraction = true;
                    bboxObj.contraction = contraction_label;
                    if (contraction_conf) {
                      bboxObj.contractionConf =
                        this.confToPercent(contraction_conf);
                    }
                  }

                  // Predator
                  if (breed_label && breed_label === "coyote") {
                    this.has_predator = true;
                    bboxObj.predator = breed_label;
                    if (breed_conf) {
                      bboxObj.predatorConf = this.confToPercent(breed_conf);
                    }
                  }
                }

                let compositeKey = `${bboxObj.tracking_id}-${bboxObj.species}-${bboxObj.x1}-${bboxObj.y1}-${bboxObj.w}-${bboxObj.h}`;
                if (
                  !this.list_of_all_bbox_classifications.some(
                    (e) =>
                      `${e.tracking_id}-${e.label}-${e.x1}-${e.y1}-${e.w}-${e.h}` ===
                      compositeKey
                  )
                ) {
                  this.list_of_all_bbox_classifications.push(bboxObj);
                  this.hasInference = true;
                  // this.sourceRadio = "inference";
                 
                }
              }
            });
          }
        }
      }

      if (this.sourceRadio === "json") {

        // Bounding Boxes
        this.list_of_all_bbox_classifications =
          this.list_of_all_bbox_classifications_json;

        this.has_contraction = this.has_contraction_json;
        this.has_age = this.has_age_json;
        this.has_activity = this.has_activity_json;
        this.has_predator = this.has_predator_json;
        this.has_gender = this.has_gender_json;
        this.has_grazing = this.has_grazing_json;
      }

      this.updateCanvas();
    },
    buildQuery(customerId, tagLabels) {
      let query = `query {
    list_animals(where: {customer_id: {_eq: "${customerId}"}`;

      if (tagLabels && tagLabels.length > 0) {
        // Include tag labels directly as strings in the array
        const tagsFilter = tagLabels
          .map((label) => `"${label.tag_label}"`)
          .join(", ");
        query += `, tag_label: {_in: [${tagsFilter}]}`;
      }

      query += `}) {
        tag_label
        tag_color
        rfid_current
        sex
        classification
        herd_id
        herd_name
        color
        name
        dob
        species
        breed
        image_timestamp
        image_id
        customer_id
      }}`;

      return query;
    },

    cropped_url(id, timestamp, customer_id, type) {
      let ymd = moment(timestamp).format("YYYY/MM/DD");
      let total_date = moment(timestamp).format("YYYY-MM-DD_HH-mm-ss");
      return `${process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE}${customer_id}/animals/images/${ymd}/${id}_${total_date}_${type}.jpg`;
    },
    toggle_animal_image() {
      this.front_image_select = !this.front_image_select;
    },
    handleImageError(currentAnimal) {
      let foundAnimalIndex = this.animals.indexOf(currentAnimal);
      this.animals[foundAnimalIndex].imageUrl = this.placeholder_img;
      this.animals[foundAnimalIndex].imageUrlBody = this.placeholder_body_img;
    },
    showOriginal() {
      this.displayingOneAnimal = false;
      this.selectedTrackingID = "";
    },
    displayAnimal(animal) {
      if (this.displayCurrentAnimal) {
        this.displayCurrentAnimal = {};
      }
      this.displayCurrentAnimal = animal;
      if (animal.breed && animal.breed == "Holstien") {
        this.displayCurrentAnimal.breed = "Holstein";
      }
      let foundTag = this.tags_list.find(
        (tag) => tag.tag_label === this.displayCurrentAnimal.tag_label
      );
      if (foundTag) {
        this.selectedTrackingID = foundTag.tracking_id;
      }
      this.displayingOneAnimal = true;
    },
    formatString(str) {
      if (str) {
        const strLower = str.toLowerCase();
        const strReq = strLower.charAt(0).toUpperCase() + strLower.slice(1);
        return strReq;
      }
      return str;
    },
    sortBy(prop) {
      this.tags_list.sort((a, b) => {
        const propA = a[prop].toLowerCase();
        const propB = b[prop].toLowerCase();

        if (propA < propB) return -1;
        if (propA > propB) return 1;
        return 0; // If the properties are equal
      });
    },
    handleCanvasClick(event) {
      var canvas = this.$refs.imageCanvas;
      const rect = canvas.getBoundingClientRect();
      const x = event.offsetX || event.clientX - rect.left;
      const y = event.offsetY || event.clientY - rect.top;
      let currentBBOXs = [];
      if (this.sourceComponent && !this.json_selected) {
        currentBBOXs = this.bbox_list;
      } else {
        currentBBOXs = this.list_of_all_bbox_classifications;
      }
      for (let i = 0; i < currentBBOXs.length; i++) {
        var x1 = this.scaleX(currentBBOXs[i].x1);
        var y1 = this.scaleY(currentBBOXs[i].y1);
        var w = this.scaleX(currentBBOXs[i].w);
        var h = this.scaleY(currentBBOXs[i].h);

        if (x >= x1 && x <= x1 + w && y >= y1 && y <= y1 + h) {
          this.changeSelectedTrackingID(currentBBOXs[i].tracking_id);
          break;
        }
      }
    },
    isSelected(trackingId) {
      return trackingId === this.selectedTrackingID;
    },
    changeSelectedTrackingID(tracking_id) {
      if (this.selectedTrackingID == tracking_id) {
        this.showOriginal();
      } else {
        this.selectedTrackingID = tracking_id;
        //find tag with tracking id
        var foundTag = this.tags_list.find(
          (tag) => tag.tracking_id === tracking_id
        );
        if (foundTag) {
          var tag_label = foundTag.tag_label;
          var foundAnimal = this.animals.find(
            (animal) => animal.tag_label === tag_label
          );
          if (foundAnimal) {
            this.displayAnimal(foundAnimal);
          }
        }
      }
    },
    async downloadFile(type) {
      this.loading = true;
      var blob_promise;
      if (type != "image") {
        blob_promise = this.fetchJSONBlob(this.image_path);
      } else {
        blob_promise = this.fetchImageBlob(this.image_path);
      }

      await blob_promise
        .then((blob) => {
          this.downloadBlob(
            blob,
            this.image_path + (type == "json" ? ".json" : "_event.jpg")
          );
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    fetchJSONBlob(image_path) {
      image_path;
      // var url = process.env.VUE_APP_COOKIE_BUCKET + image_path + ".json.zip";
      return new Promise((resolve) => {
        resolve(
          new Blob([JSON.stringify(this.json_data)], {
            type: "text/plain",
          })
        );
      });
    },
    fetchImageBlob(image_path) {
      var url = process.env.VUE_APP_COOKIE_BUCKET + image_path + "_event.jpg";
      return new Promise((resolve) => {
        fetch(url, { credentials: "include" }).then((res) => {
          resolve(res.blob());
        });
      });
    },
    downloadBlob(blob, filename) {
      var blobUrl = URL.createObjectURL(blob);
      var link = document.createElement("a");
      link.href = blobUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async delayGate(image_path) {
      await new Promise((resolve) => setTimeout(resolve, 100));
      return image_path == this.image_path;
    },
    toggle_select() {
      eventBus.$emit("select_image", this.image_path);
    },
    previousImage() {
      if (this.json_loading) {
        return;
      }
      switch (this.sourceComponent) {
        case "CalvingAnalytics":
          eventBus.$emit("previousImageCalving", this.image_index);
          break;

        case "Notifications":
          eventBus.$emit("previousImageNotifications", this.image_index);
          break;

        case "Estrus":
          eventBus.$emit("previousImageEstrus", this.image_index);
          break;

        case "Predator":
          eventBus.$emit("previousImagePredator", this.image_index);
          break;

        default:
          eventBus.$emit("previousImage", this.image_index);
          break;
      }
    },
    nextImage() {
      if (this.json_loading) {
        console.log("JSON is still loading, skipping next image action.");
        return;
      }
      switch (this.sourceComponent) {
        case "CalvingAnalytics":
          eventBus.$emit("nextImageCalving", this.image_index);
          break;

        case "Notifications":
          eventBus.$emit("nextImageNotifications", this.image_index);
          break;

        case "Estrus":
          eventBus.$emit("nextImageEstrus", this.image_index);
          break;

        case "Predator":
          eventBus.$emit("nextImagePredator", this.image_index);
          break;

        default:
          eventBus.$emit("nextImage", this.image_index);
          break;
      }
    },
    close() {
      var canvas = this.$refs.imageCanvas;
      var ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      this.dialog = false;
    },
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
      this.$nextTick(() => {
        this.updateCanvas();
      });
    },
    async fetchJSON() {
      const getMeta = (url) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve(img);
          img.onerror = (err) => reject(err);
          img.src = url;
        });
      // Use like:
      const url =
        process.env.VUE_APP_COOKIE_BUCKET + this.image_path + ".json.zip";

      this.image_width = (
        await getMeta(url.replace(".json.zip", "_event.jpg"))
      ).naturalWidth;

      this.json_loading = true;
      var zip = new JSZip();
      return new Promise((resolve) => {
        fetch(url, { credentials: "include" })
          .then((res) => {
            return res.blob();
          })
          .then((data) => {
            return zip.loadAsync(data);
          })
          .then((zip) => {
            zip.forEach((relativePath, zipEntry) => {
              zipEntry
                .async("blob")
                .then((content) => {
                  return content.text();
                })
                .then((data) => {
                  this.json_data = JSON.parse(data);
                  this.json_loading = false;
                  this.processJson();
                  resolve();
                });
            });
          });
      });
    },
    scaleX(xCoord) {
      const canvasWidth = 952;
      const image = this.$refs.hiddenImage;
      const imgAspectRatio = image.naturalWidth / image.naturalHeight;
      const canvasAspectRatio = canvasWidth / this.canvasHeight;
      let scaleFactor;
      if (imgAspectRatio < canvasAspectRatio) {
        scaleFactor = canvasWidth / image.naturalWidth;
      } else {
        scaleFactor = 535.5 / image.naturalHeight;
      }
      return Math.round(xCoord * scaleFactor);
    },
    scaleY(yCoord) {
      const canvasHeight = 535.5;
      const image = this.$refs.hiddenImage;
      const imgAspectRatio = image.naturalWidth / image.naturalHeight;
      const canvasAspectRatio = 952 / canvasHeight;
      let scaleFactor;
      if (imgAspectRatio < canvasAspectRatio) {
        scaleFactor = 952 / image.naturalWidth;
      } else {
        scaleFactor = canvasHeight / image.naturalHeight;
      }
      return Math.round(yCoord * scaleFactor);
    },

    drawIcon(ctx, icon, index, bbox) {
      const basePosition = 5;
      const offset = 40;
      const position = basePosition + offset * index;

      ctx.beginPath();
      const iconX = this.scaleX(bbox.x1) + this.scaleX(bbox.w) - position;
      const iconY = this.scaleY(bbox.y1) - 25;
      ctx.arc(iconX, iconY, 15, 0, 2 * Math.PI);
      ctx.fillStyle = "#ffffff";
      ctx.fill();
      ctx.drawImage(icon, iconX - 25 / 2, iconY - 25 / 2);
    },
    drawStackedText(ctx, text, index, bbox) {
      ctx.font = "bold 85% Arial";
      ctx.fillStyle = "white";
      ctx.textBaseline = "bottom";
      ctx.textAlign = "right";
      // Calculate text dimensions
      const textWidth = ctx.measureText(text).width;
      const textMetrics = ctx.measureText(text);
      const textHeight =
        textMetrics.actualBoundingBoxAscent +
        textMetrics.actualBoundingBoxDescent;

      const textX = this.scaleX(bbox.x1) + this.scaleX(bbox.w) - 5;
      const verticalOffset = 5;
      const textY =
        this.scaleY(bbox.y1) +
        this.scaleY(bbox.h) -
        (textHeight + verticalOffset) * index -
        verticalOffset;

      const rectanglePadding = 2;
      const backgroundHeight = textHeight + rectanglePadding * 2;
      const backgroundWidth = textWidth + rectanglePadding * 2;

      ctx.fillStyle = "rgba(0, 0, 0, 0.6)";
      ctx.fillRect(
        textX - textWidth - rectanglePadding,
        textY - textHeight - rectanglePadding - 2,
        backgroundWidth,
        backgroundHeight
      );

      // Draw the text over the background
      ctx.fillStyle = "white"; // Text color
      ctx.fillText(text, textX, textY);
    },
    drawInferenceLabels(ctx, arr) {
      if (this.inference_labels) {
        if (this.sourceRadio === "json") {
          for (let i = 0; i < arr.length; i++) {
            let textIndex = 0;
            var bbox = {
              x1: arr[i].x1,
              y1: arr[i].y1,
              w: arr[i].w,
              h: arr[i].h,
            };

            if (this.show_age) {
              if ("age" in arr[i]) {
                var ageLabel = arr[i].age;
                var formattedAgeLabel =
                  ageLabel.charAt(0).toUpperCase() + ageLabel.slice(1);
                if (formattedAgeLabel.toUpperCase() === "Cow".toUpperCase()) {
                  formattedAgeLabel = "Adult";
                }
                var ageLabelConf;
                let ageLabelText;
                if ("ageConf" in arr[i]) {
                  ageLabelConf = this.confToPercent(arr[i].ageConf);
                  ageLabelText = `Age: ${formattedAgeLabel}, ${ageLabelConf}`;
                } else {
                  ageLabelText = `Age: ${formattedAgeLabel}`;
                }

                this.drawStackedText(ctx, ageLabelText, textIndex, bbox);
                textIndex++;
              }
            }
            if (this.show_activity) {
              // Activity classification
              if ("activity" in arr[i]) {
                let activityLabel = arr[i].activity
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(arr[i].activity.includes("standing") ? "/" : " ");

                let activityConf = arr[i].activityConf
                  ? `, ${this.confToPercent(arr[i].activityConf)}`
                  : "";
                let activityLabelText = `Activity: ${activityLabel}${activityConf}`;
                this.drawStackedText(ctx, activityLabelText, textIndex, bbox);
                textIndex++;
              }
            }

            if (this.show_grazing) {
              // Grazing
              if ("grazing" in arr[i]) {
                let grazingLabel = arr[i].grazing
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ");

                let grazingConf = arr[i].grazingConf
                  ? `, ${this.confToPercent(arr[i].grazingConf)}`
                  : "";
                let grazingLabelText = `Grazing: ${grazingLabel}${grazingConf}`;
                this.drawStackedText(ctx, grazingLabelText, textIndex, bbox);
                textIndex++;
              }
            }

            if (this.show_gender) {
              // Gender
              if ("gender" in arr[i]) {
                let genderLabel = arr[i].gender
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ");

                let genderConf = arr[i].genderConf
                  ? `, ${this.confToPercent(arr[i].genderConf)}`
                  : "";
                let genderLabelText = `Gender: ${genderLabel}${genderConf}`;
                this.drawStackedText(ctx, genderLabelText, textIndex, bbox);
                textIndex++;
              }
            }

            if (
              this.show_estrus &&
              "resting_chin" in arr[i] &&
              arr[i].resting_chin === "resting_chin"
            ) {
              let restingChinLabel = arr[i].resting_chin
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .reverse()
                .join(" ");
              let restingChinConf = arr[i].resting_chinConf
                ? `, ${arr[i].resting_chinConf}`
                : "";
              let restingChinLabelText = `${restingChinLabel}${restingChinConf}`;
              this.drawStackedText(ctx, restingChinLabelText, textIndex, bbox);
              textIndex++;
            }

            if (
              this.show_estrus &&
              "mounting" in arr[i] &&
              arr[i].mounting != "not_mounting"
            ) {
              let mountingLabelText = `Mounting: ${
                arr[i].mountingConf ? arr[i].mountingConf : ""
              }`;
              this.drawStackedText(ctx, mountingLabelText, textIndex, bbox);
              textIndex++;
            }

            if (
              this.show_contraction &&
              "contraction" in arr[i] &&
              arr[i].contraction === "tail_up"
            ) {
              let contractionLabel = arr[i].contraction
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");

              let contractionConf = arr[i].contractionConf
                ? `, ${this.confToPercent(arr[i].contractionConf)}`
                : "";

              let contractionLabelText = `${contractionLabel}${contractionConf}`;
              this.drawStackedText(ctx, contractionLabelText, textIndex, bbox);
              textIndex++;
            }
            if (
              this.show_in_heat &&
              "in_heat" in arr[i] &&
              arr[i].in_heat === "in_heat"
            ) {
              let heatLabelText = `In Heat: ${
                arr[i].heatConf ? arr[i].heatConf : ""
              }`;
              this.drawStackedText(ctx, heatLabelText, textIndex, bbox);
              textIndex++;
            }

            if (this.show_predator) {
              //Predator
              if ("predator" in arr[i]) {
                let predatorLabel = arr[i].predator;

                let predatorConf = arr[i].predatorConf
                  ? `, ${arr[i].predatorConf}`
                  : "";
                let predatorLabelText = `Predator: ${predatorLabel}${predatorConf}`;
                this.drawStackedText(ctx, predatorLabelText, textIndex, bbox);
                textIndex++;
              }
            }
          }
        } else {
          let textIndex = 0;
          if (this.isEstrus) {
            if (this.show_estrus) {
              let { class_label, class_conf, bbox_x, bbox_y, bbox_w, bbox_h } =
                this.image_data;
              let bbox = { x1: bbox_x, y1: bbox_y, w: bbox_w, h: bbox_h };

              if (typeof class_label === "string") {
                let classLabel = class_label
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .reverse()
                  .join(" ");
                let classConf = this.confToPercent(class_conf);

                let estrusText = `${classLabel} : ${classConf}`;
                this.drawStackedText(ctx, estrusText, textIndex, bbox);
                textIndex++;
              }
            }
          }

          for (
            let i = 0;
            i < this.list_of_all_bbox_classifications.length;
            i++
          ) {
            textIndex = 0;
            let source = this.list_of_all_bbox_classifications[i];
            let bbox = {
              x1: source.x1,
              y1: source.y1,
              w: source.w,
              h: source.h,
            };

            if (this.show_activity) {
              // Activity classification
              let activity = source.activity;
              if (activity) {
                let activity_conf = source.activityConf || "";
                let activityLabel = activity
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(activity.includes("standing") ? "/" : " ");

                let activityLabelText = `Activity: ${activityLabel}, ${activity_conf}`;
                this.drawStackedText(ctx, activityLabelText, textIndex, bbox);
                textIndex++;
              }
            }

            if (this.show_age) {
              let age = source.age;
              if (age) {
                ageLabel = age;
                formattedAgeLabel = age.charAt(0).toUpperCase() + age.slice(1);
                if (formattedAgeLabel.toUpperCase() === "Cow".toUpperCase()) {
                  formattedAgeLabel = "Adult";
                }
                ageLabelConf;
                let ageLabelText;
                if ("ageConf" in source) {
                  ageLabelConf = source.ageConf;
                  ageLabelText = `Age: ${formattedAgeLabel}, ${ageLabelConf}`;
                } else {
                  ageLabelText = `Age: ${formattedAgeLabel}`;
                }

                this.drawStackedText(ctx, ageLabelText, textIndex, bbox);
                textIndex++;
              }
            }

            if (
              this.show_contraction &&
              "contraction" in source &&
              source.contraction === "tail_up"
            ) {
              let contractionLabel = source.contraction
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");

              let contractionConf = source.contractionConf
                ? `, ${source.contractionConf}`
                : "";

              let contractionLabelText = `${contractionLabel}${contractionConf}`;
              this.drawStackedText(ctx, contractionLabelText, textIndex, bbox);
              textIndex++;
            }
          }
        }
      }
    },
    drawBBOXByList(ctx, arr, fillStyle, strokeStyle) {
      for (let i = 0; i < arr.length; i++) {
        let iconIndex = 0;

        var calving_icon = document.getElementById("calving_cow_image");
        var chinresting_icon = document.getElementById("chinresting_cow_image");
        var mounting_icon = document.getElementById("mounting_cow_image");
        var laying_icon = document.getElementById("laying_cow_image");
        var standing_icon = document.getElementById("standing_cow_image");
        var heat_icon = document.getElementById("heat_cow_image");

        var bbox = {
          x1: arr[i].x1,
          y1: arr[i].y1,
          w: arr[i].w,
          h: arr[i].h,
        };

        ctx.fillStyle =
          this.$store.getters.getBBOXColorCode[arr[i].type]?.fillStyle ||
          fillStyle ||
          "rgba(242, 114, 0, 0.4)";
        ctx.lineWidth = 1;
        ctx.strokeStyle =
          this.$store.getters.getBBOXColorCode[arr[i].type]?.strokeStyle ||
          strokeStyle ||
          "rgba(242, 114, 0)";

        if (this.activityIcons) {
          if (this.show_activity) {
            // Activity classification
            if ("activity" in arr[i]) {
              this.drawIcon(
                ctx,
                arr[i].activity === "standing_walking"
                  ? standing_icon
                  : laying_icon,
                iconIndex,
                bbox
              );
              iconIndex++;
            }
          }

          if (
            this.show_resting_chin &&
            "resting_chin" in arr[i] &&
            arr[i].resting_chin === "resting_chin"
          ) {
            this.drawIcon(ctx, chinresting_icon, iconIndex, bbox);
            iconIndex++;
          }

          // Mounting
          if (
            this.show_mounting &&
            "mounting" in arr[i] &&
            arr[i].mounting != "not_mounting"
          ) {
            this.drawIcon(ctx, mounting_icon, iconIndex, bbox);
            iconIndex++;
          }

          // Delivery (Calving)
          if (
            this.show_delivery &&
            "delivery" in arr[i] &&
            (arr[i].delivery === "calving" || arr[i].delivery === "not_calving")
          ) {
            this.drawIcon(ctx, calving_icon, iconIndex, bbox);
            iconIndex++;
          }

          if (
            this.show_contraction &&
            "contraction" in arr[i] &&
            (arr[i].contraction === "tail_up" ||
              arr[i].contraction === "tail_down")
          ) {
            this.drawIcon(ctx, calving_icon, iconIndex, bbox);
            iconIndex++;
          }

          // In Heat
          if (
            this.show_in_heat &&
            "in_heat" in arr[i] &&
            arr[i].in_heat === "in_heat"
          ) {
            this.drawIcon(ctx, heat_icon, iconIndex, bbox);
            iconIndex++;
          }
        }
      }
    },
    // startCanvas(){
    //   this.$nextTick(()=>{
    //       const resizeObserver = new ResizeObserver(() => {
    //           this.updateCanvas();
    //       });

    //       resizeObserver.observe(this.$refs.image);
    //       this.updateCanvas()
    //   });
    // },
    updateCanvas() {
      const canvas = this.$refs.imageCanvas;
      let ctx;
      if (canvas) {
        ctx = canvas.getContext("2d");
      } else {
        return;
      }
      const image = this.$refs.hiddenImage;
      canvas.width = 952;
      canvas.height = 535.5;

      const imgAspectRatio = image.naturalWidth / image.naturalHeight;
      const canvasAspectRatio = canvas.width / canvas.height;

      let renderableWidth, renderableHeight, xStart, yStart;

      if (imgAspectRatio < canvasAspectRatio) {
        // Image is narrower than canvas (fit to width)
        renderableWidth = canvas.width;
        renderableHeight =
          image.naturalHeight * (canvas.width / image.naturalWidth);
        xStart = 0;
        yStart = (canvas.height - renderableHeight) / 2;
      } else {
        // Image is wider than canvas (fit to height)
        renderableHeight = canvas.height;
        renderableWidth =
          image.naturalWidth * (canvas.height / image.naturalHeight);
        xStart = (canvas.width - renderableWidth) / 2;
        yStart = 0;
      }

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, xStart, yStart, renderableWidth, renderableHeight);
      ctx.fillStyle = "rgba(0, 0, 0, 0.4)";

      if (this.showbos) {
        this.drawBBOX(ctx);
      }

      if (this.bodyparts) {
        this.drawBodyParts(ctx);
      }
      if (this.keypoints) {
        this.drawLines(ctx);
        this.drawKeypoints(ctx);
      }

      if (this.activityIcons) {
        this.drawBBOXByList(ctx, this.list_of_all_bbox_classifications);
      }

      if (this.inference_labels) {
        this.drawInferenceLabels(ctx, this.list_of_all_bbox_classifications);
      }
    },
    getBodyPartArrayID(str) {
      switch (str) {
        case "head":
          return [
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
          ];
        case "tail":
          return [24, 25, 26, 27, 28];
        case "body":
          return [19, 20, 21, 22, 23, 33, 38, 43, 48, 49, 50, 51];
        case "limb":
          return [
            29, 30, 31, 32, 34, 35, 36, 37, 39, 40, 41, 42, 44, 45, 46, 47,
          ];
        default:
          return [];
      }
    },
    get_color(idx) {
      var colorDB = "(4, 24, 146)"; // dark blue
      var colorBrown = "(147, 82, 0)"; // brown
      var colorPink = "(148, 32, 147)"; // dark pink
      var colorGreen = "(3, 143, 0)"; // green
      var colorLB = "(3 84 148)"; // light blue
      var ret;
      if (idx <= 10) {
        ret = colorDB;
      } else if (idx >= 11 && idx <= 14) {
        ret = colorBrown;
      } else if ((idx >= 15 && idx <= 24) || idx == 27 || idx == 28) {
        ret = colorLB;
      } else if (idx >= 25 && idx <= 26) {
        ret = colorPink;
      } else if (idx >= 29 && idx <= 32) {
        ret = colorGreen;
      } else {
        ret = colorDB;
      }
      return ret;
    },
    get_point_color(idx) {
      var colorOrange = "(255, 147, 5)"; // orange
      var colorLightPink = "(255, 63, 255)"; // light pink
      var colorBrightGreen = "(13, 249, 0)"; // bright green
      var colorDarkSkyBlue = "(8 150 255)"; // little dark than sky blue
      var colorYellow = "(255, 255, 0)"; //  yellow
      //
      var colorDB = "(4, 24, 146)";
      var colorPink = "(148, 32, 147)";
      var colorGreen = "(3, 143, 0)";
      var colorLB = "(3 84 148)";
      var ret1, ret2;
      if (idx >= 2 && idx <= 4) {
        ret1 = colorYellow;
        ret2 = colorLB;
      } else if (idx == 5 || idx == 10 || (idx >= 24 && idx <= 29)) {
        ret1 = colorOrange;
        ret2 = colorLB;
      } else if (idx == 6 || idx == 11 || (idx >= 30 && idx <= 35)) {
        ret1 = colorBrightGreen;
        ret2 = colorGreen;
      } else if (idx == 7 || idx == 12 || idx == 22 || idx == 23) {
        ret1 = colorLightPink;
        ret2 = colorPink;
      } else {
        ret1 = colorDarkSkyBlue;
        ret2 = colorDB;
      }
      return [ret1, ret2];
    },
    drawAnalyticsClassifications(ctx, bbox, type, classificationInfo) {
      let textIndex = 0;
      if (!bbox) {
        return;
      }
      if (type == "calving") {
        if (classificationInfo.length > 0) {
          if (classificationInfo[0].label1) {
            let contractionLabel = classificationInfo[0].label1
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ");

            let contractionConf = classificationInfo[0].conf1
              ? `, ${(classificationInfo[0].conf1 * 100).toFixed(0)}%`
              : "";
            let contractionLabelText = `V1: ${contractionLabel}${contractionConf}`;
            this.drawStackedText(ctx, contractionLabelText, textIndex, bbox);
            textIndex++;
          }

          if (classificationInfo[0].label2) {
            let contractionLabel = classificationInfo[0].label2
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ");

            let contractionConf = classificationInfo[0].conf2
              ? `, ${(classificationInfo[0].conf2 * 100).toFixed(0)}%`
              : "";
            let contractionLabelText = `V2: ${contractionLabel}${contractionConf}`;
            this.drawStackedText(ctx, contractionLabelText, textIndex, bbox);
            textIndex++;
          }
        }
      } else if (type == "estrus") {
        if (classificationInfo.length > 0) {
          if (classificationInfo[0].label1) {
            let classLabel = classificationInfo[0].label1
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ");

            let classConf = classificationInfo[0].conf1
              ? `, ${(classificationInfo[0].conf1 * 100).toFixed(0)}%`
              : "";
            let classLabelText = `V1: ${classLabel}${classConf}`;
            this.drawStackedText(ctx, classLabelText, textIndex, bbox);
            textIndex++;
          }

          if (classificationInfo[0].label2) {
            let classLabel = classificationInfo[0].label2
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ");

            let classConf = classificationInfo[0].conf2
              ? `, ${(classificationInfo[0].conf2 * 100).toFixed(0)}%`
              : "";
            let classLabelText = `V2: ${classLabel}${classConf}`;
            this.drawStackedText(ctx, classLabelText, textIndex, bbox);
            textIndex++;
          }
        }
      } else if (type == "predator") {
        if (classificationInfo.length > 0) {
          if (classificationInfo[0]?.label1) {
            let classLabel = classificationInfo[0].label1
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ");

            let classConf = classificationInfo[0].conf1
              ? `, ${(classificationInfo[0].conf1 * 100).toFixed(0)}%`
              : "";
            let classLabelText = `${classLabel}${classConf}`;
            this.drawStackedText(ctx, classLabelText, textIndex, bbox);
            textIndex++;
          }
        }
      }
    },
    drawBBOX(ctx) {
      let annotationsSource = [];

      // Source Component is  null

      if (this.sourceComponent == null) {
        // Imagetable and ImageCard
        // Handle the case where the souce is inference or json
        if (this.sourceRadio === "inference" || this.sourceRadio === "json") {
          annotationsSource = this.list_of_all_bbox_classifications;
        } else {
          // Case where the annotation source is analaytics
          if (this.isEstrus) {
            if (this.bbox_list.length > 2) {
              annotationsSource = this.bbox_list.slice(0, 2);
            } else {
              annotationsSource = this.bbox_list;
              this.drawAnalyticsClassifications(
                ctx,
                this.bbox_list[0],
                "estrus",
                this.classificationInfo
              );
            }
          } else {
            annotationsSource = this.analytics_annotations_bbox;
            let classificationObject =
              this.analytics_annotations_classifications;
            Object.keys(classificationObject).forEach((key) => {
              if (key == "calving") {
                this.drawAnalyticsClassifications(
                  ctx,
                  classificationObject[key].bbox,
                  "calving",
                  classificationObject[key].classification
                );
              } else if (key == "estrus") {
                this.drawAnalyticsClassifications(
                  ctx,
                  classificationObject[key].bbox,
                  "estrus",
                  classificationObject[key].classification
                );
              } else if (key == "predator") {
                this.drawAnalyticsClassifications(
                  ctx,
                  classificationObject[key].bbox,
                  "predator",
                  classificationObject[key].classification
                );
              }
            });
          }
        }
      } else {
        // Analytics
        if (this.sourceRadio === "inference" || this.sourceRadio === "json") {
          annotationsSource = this.list_of_all_bbox_classifications;
        } else {
          if (this.sourceComponent === "CalvingAnalytics") {
            annotationsSource = this.bbox_list;
            this.drawAnalyticsClassifications(
              ctx,
              annotationsSource[0],
              "calving",
              this.classificationInfo
            );
          } else if (this.sourceComponent === "Estrus") {
            if (this.bbox_list.length > 2) {
              annotationsSource = this.bbox_list.slice(0, 2);
            } else {
              annotationsSource = this.bbox_list;
              this.drawAnalyticsClassifications(
                ctx,
                this.bbox_list[0],
                "estrus",
                this.classificationInfo
              );
            }
          } else {
            annotationsSource = this.bbox_list;
            this.drawAnalyticsClassifications(
              ctx,
              this.bbox_list[0],
              "predator",
              this.classificationInfo
            );
          }
        }
      }
      this.animalsInFrame = annotationsSource.length;
      ctx.textAlign = "start";
      ctx.textBaseline = "alphabetic";
      for (let i = 0; i < annotationsSource.length; i++) {
        let x1 = this.scaleX(annotationsSource[i].x1);
        let y1 = this.scaleY(annotationsSource[i].y1);
        let w = this.scaleX(annotationsSource[i].w);
        let h = this.scaleY(annotationsSource[i].h);

        if (annotationsSource[i]?.tracking_id === this.selectedTrackingID) {
          ctx.fillStyle = "rgba(0, 144, 164, 0.4)";
          ctx.strokeStyle = "rgba(0,0,0, 0.8)";
        } else {
          ctx.fillStyle =
            this.$store.getters.getBBOXColorCode[annotationsSource[i]?.species]
              ?.fillStyle || "rgba(242, 114, 0, 0.4)";

          ctx.strokeStyle =
            this.$store.getters.getBBOXColorCode[annotationsSource[i]?.species]
              ?.strokeStyle || "rgba(242, 114, 0, 0.8)";
        }

        if (this.bbox_fill) {
          ctx.fillRect(x1, y1, w, h);
        }
        ctx.lineWidth = 4;
        ctx.strokeRect(x1, y1, w, h);

        if (this.labels) {
          ctx.fillStyle = "rgba(29, 107, 118,0.9)";
          ctx.font = `bold 85% Arial`;
          let formattedPrimarylabel = "";
          let bboxText = "";
          if (annotationsSource[i]?.species != "animal") {
            if (annotationsSource[i]?.species) {
              formattedPrimarylabel =
                annotationsSource[i]?.species.charAt(0).toUpperCase() +
                annotationsSource[i]?.species.slice(1);
              const speciesConfPercent = (
                annotationsSource[i]?.speciesConf * 100
              ).toFixed(0);
              bboxText = `${formattedPrimarylabel}: ${speciesConfPercent}%`;
            }
          } else {
            if (annotationsSource[i]?.label) {
              formattedPrimarylabel =
                annotationsSource[i]?.label.charAt(0).toUpperCase() +
                annotationsSource[i]?.label.slice(1);
              if (annotationsSource[i]?.conf) {
                const confPercent = (annotationsSource[i]?.conf * 100).toFixed(
                  0
                );
                bboxText = `${formattedPrimarylabel}: ${confPercent}%`;
              }
            }
          }

          if (bboxText) {
            let textWidth = ctx.measureText(bboxText).width;
            let textMetrics = ctx.measureText(bboxText);
            let textHeight =
              textMetrics.actualBoundingBoxAscent +
              textMetrics.actualBoundingBoxDescent;
            let bboxRectangleHeight = textHeight + 1;
            let bboxRectangleWidth = textWidth + 1;
            ctx.fillRect(
              x1,
              y1 - 7 - textHeight,
              bboxRectangleWidth + 3,
              bboxRectangleHeight + 3
            );

            ctx.fillStyle = "white";
            ctx.fillText(bboxText, x1 + 2, y1 - 6);
          }
        }
      }

      if (this.isEstrus) {
        if (this.bbox_list[2]) {
          let x1 = this.scaleX(this.bbox_list[2].x1);
          let y1 = this.scaleY(this.bbox_list[2].y1);
          let w = this.scaleX(this.bbox_list[2].w);
          let h = this.scaleY(this.bbox_list[2].h);

          if (this.bbox_list[2]?.tracking_id === this.selectedTrackingID) {
            ctx.strokeStyle = "rgba(0,0,0, 0.8)";
          } else {
            ctx.strokeStyle =
              this.$store.getters.getBBOXColorCode[this.bbox_list[2]?.species]
                ?.strokeStyle || "rgba(242, 114, 0, 0.8)";
          }

          // if (this.bbox_fill) {
          //   ctx.fillRect(x1, y1, w, h);
          // }
          ctx.lineWidth = 4;
          ctx.setLineDash([10, 5]);
          ctx.beginPath();
          ctx.moveTo(x1, y1);
          ctx.lineTo(x1 + w, y1); // Top side
          ctx.lineTo(x1 + w, y1 + h); // Right side
          ctx.lineTo(x1, y1 + h); // Bottom side
          ctx.lineTo(x1, y1);
          ctx.stroke();
          ctx.setLineDash([]);
        }
        this.drawAnalyticsClassifications(
          ctx,
          this.bbox_list[2],
          "estrus",
          this.classificationInfo
        );
      }
    },

    drawBodyParts(ctx) {
      let annotationsSource = [];
      if (this.sourceComponent == null) {
        if (this.sourceRadio === "inference" || this.sourceRadio === "json") {
          annotationsSource = this.body_parts_list;
        } else {
          annotationsSource = this.analytics_annotations_bp;
        }
      } else {
        annotationsSource = this.body_parts_list;
      }
      // annotationsSource = this.body_parts_list;
      if (annotationsSource.length > 0) {
        for (let i = 0; i < annotationsSource.length; i++) {
          if (!annotationsSource[i].bbox) {
            return;
          }

          let x1 = this.scaleX(annotationsSource[i].bbox.x);
          let y1 = this.scaleY(annotationsSource[i].bbox.y);
          let w = this.scaleX(annotationsSource[i].bbox.w);
          let h = this.scaleY(annotationsSource[i].bbox.h);

          let label = annotationsSource[i].label;

          ctx.fillStyle = "rgba(0, 154, 255, 0.3)";
          if (label == "tag") {
            ctx.fillStyle = "rgba(55, 255, 255, 0.4)";
          } else if (label == "head") {
            ctx.fillStyle = "rgba(0, 154, 255, 0.4)";
          } else if (label == "knee") {
            ctx.fillStyle = "rgba(254, 255, 0, 0.4)";
          } else if (label == "hoof") {
            ctx.fillStyle = "rgba(96, 217, 55, 0.4)";
          } else if (label == "tail") {
            ctx.fillStyle = "rgba(255, 66, 161, 0.4)";
          } else {
            ctx.fillStyle = "rgba(96, 217, 55, 0.4)";
          }
          ctx.fillRect(x1, y1, w, h);

          // Set stroke color based on label
          ctx.lineWidth = 3;
          if (label == "tag") {
            ctx.strokeStyle = "rgba(55, 255, 255, 0.4)";
          } else if (label == "head") {
            ctx.strokeStyle = "rgba(0, 154, 255, 0.4)";
          } else if (label == "knee") {
            ctx.strokeStyle = "rgba(254, 255, 0, 0.4)";
          } else if (label == "hoof") {
            ctx.strokeStyle = "rgba(96, 217, 55, 0.4)";
          } else if (label == "tail") {
            ctx.strokeStyle = "rgba(255, 66, 161, 0.4)";
          } else {
            ctx.strokeStyle = "rgba(96, 217, 55, 0.4)";
          }

          ctx.strokeRect(x1, y1, w, h);

          if (this.labels) {
            ctx.fillStyle = "rgba(29, 107, 118,0.9)";
            let bodyPartsText;

            let formattedBodyLabel =
              label.charAt(0).toUpperCase() + label.slice(1);
            if (annotationsSource[i].conf) {
              let conf = (annotationsSource[i].conf * 100).toFixed(0);
              if (label == "tag" && annotationsSource[i].tag_id) {
                bodyPartsText = formattedBodyLabel + " " + conf + "%";
                if (annotationsSource[i].tag_id_conf) {
                  bodyPartsText +=
                    " : " +
                    annotationsSource[i].tag_id +
                    " " +
                    (annotationsSource[i].tag_id_conf * 100).toFixed(0) +
                    "%";
                } else {
                  bodyPartsText += " : " + annotationsSource[i].tag_id;
                }
              } else {
                bodyPartsText = formattedBodyLabel + ": " + conf + "%";
              }
            } else {
              bodyPartsText = formattedBodyLabel;
            }

            ctx.font = "bold 70% Arial";
            let textWidth = ctx.measureText(bodyPartsText).width;
            let textMetrics = ctx.measureText(bodyPartsText);
            let textHeight =
              textMetrics.actualBoundingBoxAscent +
              textMetrics.actualBoundingBoxDescent;

            ctx.fillRect(
              x1,
              y1 - textHeight - 5,
              textWidth + 3,
              textHeight + 3
            );
            ctx.fillStyle = "white";
            ctx.fillText(bodyPartsText, x1 + 2, y1 - 5);
          }
        }
      }
    },
    drawLines(ctx) {
      if (this.options.json) {
        for (let i = 0; i < this.list_of_lines.length; i++) {
          var x1 = this.scaleX(this.list_of_lines[i].x1);
          var y1 = this.scaleY(this.list_of_lines[i].y1);
          var x2 = this.scaleX(this.list_of_lines[i].x2);
          var y2 = this.scaleY(this.list_of_lines[i].y2);
          ctx.beginPath();
          ctx.moveTo(x1, y1);
          ctx.lineTo(x2, y2);
          ctx.lineWidth = 1;
          ctx.strokeStyle = "rgb" + this.list_of_lines[i].linecolor;
          ctx.stroke();
        }
      }
    },
    drawKeypoints(ctx) {
      if (this.options.json) {
        for (let i = 0; i < this.list_of_keypoints.length; i++) {
          var x1 = this.scaleX(this.list_of_keypoints[i].x1);
          var y1 = this.scaleY(this.list_of_keypoints[i].y1);
          ctx.strokeStyle = "rgb" + this.list_of_keypoints[i].color2;
          ctx.lineWidth = 4;
          ctx.beginPath();
          ctx.arc(x1, y1, 3, 0, 2 * Math.PI);
          ctx.stroke();
          ctx.fillStyle = "rgb" + this.list_of_keypoints[i].color1;
          ctx.fill();
        }
      }
    },
    registerJSONElement() {},
  },
  computed: {
    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET;
    },
    filteredSearchTags() {
      if (!this.search) {
        return this.tags_list;
      }
      const searchLower = this.search.toLowerCase();
      return this.tags_list.filter((tag) => {
        const labelMatch = tag.tag_label.toLowerCase().includes(searchLower);
        const colorMatch =
          tag.tag_color && tag.tag_color.toLowerCase().includes(searchLower);
        return labelMatch || colorMatch;
      });
    },
  },
  created() {
    // BBOX
    this.isShowBos = this.showbos;
    this.showbos = false;
    this.showbos = this.isShowBos;

    this.isShowEstrus = this.show_estrus;
    this.show_estrus = false;
    this.show_estrus = this.isShowEstrus;

    this.isBodyParts = this.bodyparts;
    this.bodyparts = false;
    this.bodyparts = this.isBodyParts;

    this.isKeyPoints = this.keypoints;
    this.keypoints = false;
    this.keypoints = this.isKeyPoints;

    this.isLabels = this.labels;
    this.labels = false;
    this.labels = this.isLabels;

    this.isInferenceLabels = this.inference_labels;
    this.inference_labels = false;
    this.inference_labels = this.isInferenceLabels;

    this.isbbox_fill = this.bbox_fill;
    this.bbox_fill = false;
    this.bbox_fill = this.isbbox_fill;

    this.isShowContraction = this.show_contraction;
    this.show_contraction = false;
    this.show_contraction = this.isShowContraction;

    this.isShowContractionClassification = this.show_contraction_classification;
    this.show_contraction_classification = false;
    this.show_contraction_classification = this.isShowContractionClassification;

    this.isShowPredator = this.show_predator;
    this.show_predator = false;
    this.show_predator = this.isShowPredator;

    this.isShowActivity = this.show_activity;
    this.show_activity = false;
    this.show_activity = this.isShowActivity;

    this.isShowGender = this.show_gender;
    this.show_gender = false;
    this.show_gender = this.isShowGender;

    this.isShowGrazing = this.show_grazing;
    this.show_grazing = false;
    this.show_grazing = this.isShowGrazing;

    this.isShowAge = this.show_age;
    this.show_age = false;
    this.show_age = this.isShowAge;

    this.isActivityIcons = this.activityIcons;
    this.activityIcons = false;
    this.activityIcons = this.isActivityIcons;

    eventBus.$on("open_dialog_images", (params) => {
      this.open(params);
    });
    window.addEventListener("keyup", (e) => {
      if (this.dialog && e.code == "Escape") {
        this.close();
      }
    });
  },
  mounted() {
    this.checkScreenWidth();
    window.addEventListener("resize", this.checkScreenWidth);
    this.keyDownListener = (e) => {
      if (this.dialog) {
        if (e.code == "ArrowLeft") {
          this.previousImage();
        }

        if (e.code == "ArrowRight") {
          this.nextImage();
        }
      }
    };
    window.addEventListener("keydown", this.keyDownListener);

    this.keyUpListener = (e) => {
      if (this.dialog) {
        if (e.code == "Space") {
          e.stopPropagation();
          this.downloadFile("image");
        }
      }
    };
    window.addEventListener("keyup", this.keyUpListener);
  },

  beforeDestroy() {
    window.removeEventListener("keydown", this.keyDownListener);
    window.removeEventListener("keyup", this.keyUpListener);
  },
};
</script>

<style scoped>
.load_image {
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.main-container {
  max-width: 100%;
  margin: 0;
  padding: 0;
  margin-top: -2%;
}
.left-column,
.right-column {
  display: flex;
  flex-direction: column;
  height: 535px;
}

.custom-style {
  display: flex;
  flex-direction: column;
  height: 500px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}
.inference-title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px; /* Adjust padding to be closer to the text */
  text-transform: uppercase;
  color: #0090a4;
  font-size: small;
  font-weight: bold;
}
.middle {
  display: flex;
  justify-content: center;
  align-content: center;
}

.container-vp {
  padding: 0;
  margin: 0;
}

.video-container {
  display: flex;
  justify-content: center;
  margin-inline: auto;
  position: relative;
  align-items: center;
  height: 535px !important;
}

.panZoom-container {
  width: 100%;
  height: 100%;
}
.toggle-switches-events-visuals {
  margin-left: 5%;
  margin-top: 3%;
}

.label-container {
  margin-top: -10%;
}

.v-input--selection-controls {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  padding-top: 0px !important;
}
/* .toggle-switches {
  margin-top: -5%;
  margin-left: 5%;
} */

.toggle-switches {
  margin-top: -4%;
  margin-left: 10%;
  width: 80%;
}

.toggle-switches-events {
  margin-left: 10%;
  margin-top: -2%;
}
.toggle-switches-events-calving {
  margin-top: -13%;
  margin-left: 5%;
}
.toggle-switches-events-inference {
  margin-left: 5%;
  margin-top: 10%;
}

.inference-title-visuals {
  text-transform: uppercase;
  color: #0090a4;
  font-size: small;
  margin-top: -10%;
}
.title-spacing {
  text-transform: uppercase;
  color: #0090a4;
  font-size: small;
  margin-top: -15%;
  padding-bottom: 3%;
}
.custom-style::-webkit-scrollbar {
  width: 2px;
}

.custom-style::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.custom-style:hover {
  scrollbar-color: lightgrey transparent;
}

.custom-style:hover::-webkit-scrollbar-thumb {
  background-color: lightgrey;
}

.chip-style {
  color: black;
  font-weight: 500;
  margin-bottom: -3px;
}
.image_selector_icons_ac {
  height: 14px;
  display: inline-block;
}
.image_selector_ac {
  height: 24px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 10px;
}
</style>
